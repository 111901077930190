.page-header {

    &__bar {
        transition: transform 0.4s;
        @apply ease-out-quart;
        
        .is-hidden & {
            transform: translateY(-61px);
            
            @screen m {
                transform: translateY(-81px);
            }
        }
        
        opacity: 0;
        
        .no-js &,
        .js-error & {
            opacity: 1;
        }
    }

    &__logo {
        width: 70px;
        height: 75px;
        left: calc(50% - 35px);
        top: 15px;

        @screen m {
            width: 90px;
            height: 97px;
            left: calc(50% - 45px);
            top: 20px;
        }
        
        transition-property: width, height, left, top;
        transition-duration: 0.3s;
        @apply ease-out-quart;

        .is-collapsed & {
            width: 56px;
            height: 60px;
            left: calc(50% - 28px);
            top: 10px;

            @screen m {
                width: 70px;
                height: 76px;
                left: calc(50% - 35px);
                top: 12px;
            }
        }
    }

    &__logo-symbol {

    }

    &__logo-text {
        transition-property: opacity;
        transition-duration: 0.1s;
        transition-delay: 0s;
        transition-timing-function: linear;
        
        .is-collapsed & {
            opacity: 0;
            pointer-events: none;
            transition-delay: 0s;
        }
    }

    &__bar-inner {
        height: 51px;
        transition-property: height;
        transition-duration: 0.3s;
        @apply ease-out-quart;
        
        @screen m {
            height: 66px;
        }

        .is-collapsed & {
            height: 61px;

            @screen m {
                height: 75px;
            }
        }
    }

    &__bar-inner-padder {
        padding-top: 5px;
        transition-property: padding-top;
        transition-duration: 0.3s;
        @apply ease-out-quart;

        @screen m {
            padding-top: 14px;
        }

        .is-collapsed & {
            padding-top: 10px;
            
            @screen m {
                padding-top: 19px;
            }
        }
    }

    &__btn {
        display: block;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0;

        @media print {
            display: none;
        }

        &.-menu {
            left: -7px;
            
             @screen m {
                 left: -3px;
             }

            span {
                display: block;
                position: absolute;
                width: 26px;
                height: 3px;
                left: 7px;
                background: currentColor;
                
                @screen m {
                    width: 34px;
                    height: 4px;
                    left: 3px;
                }
            }
            span:nth-child(1) {
                top: 10px;
            }

            span:nth-child(2) {
                top: 18px;
            }

            span:nth-child(3) {
                top: 26px;
            }
            
            @screen m {
                span:nth-child(1) {
                    top: 6px;
                }
    
                span:nth-child(2) {
                    top: 16px;
                }
    
                span:nth-child(3) {
                    top: 26px;
                }
            }
        }

        &.-search {
            left: 35px;

            @screen m {
                left: 55px;
            }

            svg {
                position: absolute;
                width: 21px;
                height: 21px;
                left: 10px;
                top: 9px;

                @screen m {
                    width: 28px;
                    height: 28px;
                    top: 4px;
                    left: 7px;
                }
            }
        }
        
        &.-darkmode {
            left: 70px;

            @screen m {
                left: 100px;
            }

            svg {
                position: absolute;
                width: 21px;
                height: 21px;
                left: 10px;
                top: 9px;

                @screen m {
                    width: 28px;
                    height: 28px;
                    top: 4px;
                    left: 7px;
                }
            }
        }
    }

    &__bar-line {
        width: calc(50% - 50px);
        top: 50px;

        transition-property: top, width;
        transition-duration: 0.3s, 0.2s;
        transition-delay: 0s, 0s;
        transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000), cubic-bezier(0.230, 1.000, 0.320, 1.000);
        
        @screen m {
            width: calc(50% - 70px);
            top: 65px;
        }

        .is-collapsed & {
            width: 50%;
            top: 60px;
            transition-duration: 0.3s, 0.3s;
            transition-delay: 0s, 0.1s;
            transition-timing-function: cubic-bezier(0.165, 0.840, 0.440, 1.000), cubic-bezier(0.230, 1.000, 0.320, 1.000);
            
            @screen m {
                top: 75px;
            }
        }
    }
}
